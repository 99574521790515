export default [
  {
    path: '/testchat',
    name: 'test-chat',
    component: () => import('@/views/admin/testchat.vue'),
  },
  {
    path: '/dashboard',
    name: 'admin-dashboard',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      resource: 'admin-dashboard',
    },
  },
  {
    path: '/admin/chat',
    name: 'admin-chat',
    component: () => import('@/views/admin/chat/ChatAdmin.vue'),
    meta: {
      // redirectIfLoggedIn: true,
      resource: 'admin-chat',
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },
  // {
  //   path: '/admin/profile',
  //   name: 'admin-profile',
  //   component: () => import('@/views/admin/profile/profile.vue'),
  // },
  {
    path: '/admin/cart',
    name: 'admin-cart',
    component: () => import('@/views/admin/cart/cart.vue'),
  },
  {
    path: '/admin/checkout',
    name: 'admin-checkout',
    component: () => import('@/views/admin/checkout/view.vue'),
  },
  {
    path: '/admin/order',
    name: 'admin-order',
    component: () => import('@/views/admin/order/order.vue'),
    meta: {
      resource: 'admin-order',
    },
  },
  {
    path: '/admin/order/detail/:id',
    name: 'admin-order-detail',
    component: () => import('@/views/admin/order/order-detail.vue'),
    meta: {
      resource: 'admin-order',
    },
  },
  {
    path: '/admin/order/detail/view/:id',
    name: 'admin-order-detail-view',
    component: () => import('@/views/admin/order/order-detail-view.vue'),
    meta: {
      resource: 'admin-order',
    },
  },
  // {
  //   path: '/admin/customer',
  //   name: 'admin-customer',
  //   component: () => import('@/views/admin/customer/customer.vue'),
  // },
  {
    path: '/admin/create-customer',
    name: 'admin-customer-add',
    component: () => import('@/views/admin/customer/customer-add.vue'),
  },
  {
    path: '/admin/employee',
    name: 'admin-employee',
    component: () => import('@/views/admin/employee/employee.vue'),
    meta: {
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/create-employee',
    name: 'admin-employee-add',
    component: () => import('@/views/admin/employee/employee-add.vue'),
    meta: {
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/edit-employee/:id',
    name: 'admin-employee-edit',
    component: () => import('@/views/admin/employee/employee-edit.vue'),
    meta: {
      resource: 'admin-user',
    },
  },
  {
    path: '/admin/payment/user',
    name: 'admin-payment-user',
    component: () => import('@/views/admin/payment/user.vue'),
    meta: {
      resource: 'admin-payment',
    },
  },
  {
    path: '/admin/payment/create/:id',
    name: 'admin-payment-create',
    component: () => import('@/views/admin/payment/create.vue'),
    meta: {
      resource: 'admin-payment',
    },
  },
  {
    path: '/admin/postnumber/noname',
    name: 'admin-postnumber-noname',
    component: () => import('@/views/admin/postnumber_noname/page.vue'),
    meta: {
      resource: 'admin-payment',
    },
  },
  {
    path: '/admin/paymenttochina',
    name: 'admin-paymenttochina',
    component: () => import('@/views/admin/paymenttochina/paymenttochina.vue'),
    meta: {
      resource: 'admin-paymenttochina',
    },
  },
  {
    path: '/admin/paymenttochina/create/:id',
    name: 'admin-paymenttochina-create',
    component: () => import('@/views/admin/paymenttochina/paymenttochina-add.vue'),
    meta: {
      resource: 'admin-paymenttochina',
    },
  },
  {
    path: '/admin/paymenttochina/edit/:id',
    name: 'admin-paymenttochina-edit',
    component: () => import('@/views/admin/paymenttochina/paymenttochina-edit.vue'),
  },
  {
    path: '/admin/finance/receipt',
    name: 'admin-finance-receipt',
    component: () => import('@/views/admin/finance/receipt.vue'),
    meta: {
      resource: 'admin-receipt',
    },
  },
  {
    path: '/admin/finance/receipt_edit/:id',
    name: 'admin-finance-receipt_edit',
    component: () => import('@/views/admin/finance/receipt_edit.vue'),
    meta: {
      resource: 'admin-receipt',
    },
  },
  {
    path: '/admin/order-print',
    name: 'admin-order-print',
    component: () => import('@/views/admin/order-print.vue'),
    meta: {
      resource: 'admin-order-print',
    },
  },
  {
    path: '/admin/order-edit/:id',
    name: 'admin-order-edit',
    component: () => import('@/views/admin/order/order_edit.vue'),
    meta: {
      resource: 'admin-order-print',
    },
  },
  {
    path: '/admin/shipment/payment',
    name: 'admin-payment',
    component: () => import('@/views/admin/shipment/payment.vue'),
    meta: {
      resource: 'admin-pay-importfee',
    },
  },
  {
    path: '/admin/shipment/payment_edit/:id',
    name: 'shipment_payment_edit',
    component: () => import('@/views/admin/shipment/payment_edit.vue'),
    meta: {
      resource: 'admin-pay-importfee',
    },
  },
  {
    path: '/admin/shipment/export',
    name: 'admin-export',
    component: () => import('@/views/admin/shipment/export.vue'),
    meta: {
      resource: 'admin-export',
    },
  },
  {
    path: '/admin/shipment/export_edit/:id',
    name: 'admin-export_edit',
    component: () => import('@/views/admin/shipment/export_edit.vue'),
    meta: {
      resource: 'admin-export',
    },
  },
  {
    path: '/admin/finance/refill',
    name: 'admin-refill',
    component: () => import('@/views/admin/finance/refill.vue'),
    meta: {
      resource: 'admin-refill',
    },
  },
  {
    path: '/admin/finance/statement',
    name: 'admin-statement',
    component: () => import('@/views/admin/finance/statement.vue'),
    meta: {
      resource: 'admin-statement',
    },
  },
  {
    path: '/admin/lost-package',
    name: 'admin-lost-package',
    component: () => import('@/views/admin/lost-package/lost-package.vue'),
    meta: {
      resource: 'admin-productfailed',
    },
  },
  {
    path: '/admin/lost-package/edit/:id',
    name: 'admin-lost-package-edit',
    component: () => import('@/views/admin/lost-package/lost-package-edit.vue'),
    meta: {
      resource: 'admin-productfailed',
    },
  },
  {
    path: '/admin/warehouse/lot',
    name: 'admin-warehouse-lot',
    component: () => import('@/views/admin/warehouse/lot/lot.vue'),
    meta: {
      resource: 'admin-lot',
    },
  },
  {
    path: '/admin/warehouse/create',
    name: 'admin-warehouse-create',
    component: () => import('@/views/admin/warehouse/lot/lot-add.vue'),
  },
  {
    path: '/admin/warehouse/edit/:id',
    name: 'admin-warehouse-edit',
    component: () => import('@/views/admin/warehouse/lot/lot-edit.vue'),
    meta: {
      resource: 'admin-lot',
    },
  },
  {
    path: '/admin/warehouse/thai',
    name: 'admin-warehouse-thai',
    component: () => import('@/views/admin/warehouse/thai/thai.vue'),
    meta: {
      resource: 'admin-warehouse-thai',
    },
  },
  {
    path: '/admin/warehouse/thai/create',
    name: 'admin-warehouse-thai-create',
    component: () => import('@/views/admin/warehouse/thai/thai-add.vue'),
  },
  {
    path: '/admin/warehouse/thai/edit/:id',
    name: 'admin-warehouse-thai-edit',
    component: () => import('@/views/admin/warehouse/thai/thai-edit.vue'),
    meta: {
      resource: 'admin-warehouse-thai',
    },
  },
  {
    path: '/admin/warehouse/qc',
    name: 'admin-warehouse-qc',
    component: () => import('@/views/admin/warehouse/postqc/qc.vue'),
    meta: {
      resource: 'admin-warehouse-qc',
    },
  },
  {
    path: '/admin/warehouse/qc/edit/:id',
    name: 'admin-warehouse-qc-edit',
    component: () => import('@/views/admin/warehouse/postqc/qc-edit.vue'),
    meta: {
      resource: 'admin-warehouse-qc',
    },
  },
  {
    path: '/admin/warehouse/section',
    name: 'admin-warehouse-section',
    component: () => import('@/views/admin/warehouse/section/section.vue'),
    meta: {
      resource: 'admin-warehouse',
    },
  },
  {
    path: '/admin/warehouse/section/create',
    name: 'admin-warehouse-section-create',
    component: () => import('@/views/admin/warehouse/section/section-create.vue'),
    meta: {
      resource: 'admin-warehouse',
    },
  },
  {
    path: '/admin/warehouse/section/edit/:id',
    name: 'admin-warehouse-section-edit',
    component: () => import('@/views/admin/warehouse/section/section-edit.vue'),
    meta: {
      resource: 'admin-warehouse',
    },
  },
  {
    path: '/admin/balance',
    name: 'admin-balance',
    component: () => import('@/views/admin/book-account/book-list.vue'),
    meta: {
      resource: 'admin-balance',
    },
  },
  {
    path: '/admin/balance/create',
    name: 'admin-balance-create',
    component: () => import('@/views/admin/book-account/book-create.vue'),
  },
  {
    path: '/admin/balance/edit/:id',
    name: 'admin-balance-edit',
    component: () => import('@/views/admin/book-account/book-edit.vue'),
  },
  // {
  //   path: '/admin/warehouse/lot',
  //   name: 'admin-lot',
  //   component: () => import('@/views/admin/warehouse/lot.vue'),
  // },
  // {
  //   path: '/admin/warehouse/thai',
  //   name: 'admin-thai',
  //   component: () => import('@/views/admin/warehouse/thai.vue'),
  // },
  // {
  //   path: '/admin/warehouse/qc',
  //   name: 'admin-qc',
  //   component: () => import('@/views/admin/warehouse/qc.vue'),
  // },
  {
    path: '/admin/tracking',
    name: 'admin-tracking',
    component: () => import('@/views/admin/tracking/tracking.vue'),
    meta: {
      resource: 'admin-tracking',
    },
  },
  {
    path: '/admin/tracking/create',
    name: 'admin-tracking-create',
    component: () => import('@/views/admin/tracking/tracking-add.vue'),
    meta: {
      resource: 'admin-tracking',
    },
  },
  {
    path: '/admin/tracking/edit/:id',
    name: 'admin-tracking-edit',
    component: () => import('@/views/admin/tracking/tracking-edit.vue'),
    meta: {
      resource: 'admin-tracking',
    },
  },
  // รวมพัสดุ
  {
    path: '/admin/combine_track',
    name: 'admin-combine_track',
    component: () => import('@/views/admin/tracking/together-number.vue'),
    meta: {
      resource: 'admin-combine_track',
    },
  },
  {
    path: '/admin/combine-track',
    name: 'admin-combine-track',
    component: () => import('@/views/admin/combine-track.vue'),
  },
  {
    path: '/admin/china/transfer',
    name: 'admin-transfer',
    component: () => import('@/views/admin/china/transfer/transfer.vue'),
    meta: {
      resource: 'admin-transfer',
    },
  },
  {
    path: '/admin/china/transfer/:id',
    name: 'admin-transfer-check',
    component: () => import('@/views/admin/china/transfer/transfer-check.vue'),
    meta: {
      resource: 'admin-transfer',
    },
  },
  {
    path: '/admin/china/alipay',
    name: 'admin-china-alipay',
    component: () => import('@/views/admin/china/alipay/alipay.vue'),
    meta: {
      resource: 'admin-alipay',
    },
  },
  {
    path: '/admin/china/alipay/:id',
    name: 'admin-china-alipay-check',
    component: () => import('@/views/admin/china/alipay/alipay-check.vue'),
    meta: {
      resource: 'admin-alipay',
    },
  },
  {
    path: '/admin/china/payfor',
    name: 'admin-china-payfor',
    component: () => import('@/views/admin/china/payfor/payfor.vue'),
    meta: {
      resource: 'admin-payfor',
    },
  },
  {
    path: '/admin/china/payfor/:id',
    name: 'admin-china-payfor-check',
    component: () => import('@/views/admin/china/payfor/payfor-check.vue'),
    meta: {
      resource: 'admin-payfor',
    },
  },
  {
    path: '/admin/china/payfor-refund/:id',
    name: 'admin-china-payfor-refund-edit',
    component: () => import('@/views/admin/china/payfor/payfor-refund-edit.vue'),
    meta: {
      resource: 'admin-payfor',
    },
  },
  {
    path: '/admin/notice',
    name: 'admin-notice',
    component: () => import('@/views/admin/notice/notice.vue'),
    meta: {
      resource: 'admin-notice',
    },
  },
  {
    path: '/admin/notice/add',
    name: 'admin-notice-add',
    component: () => import('@/views/admin/notice/notice-add.vue'),
    meta: {
      resource: 'admin-notice',
    },
  },
  {
    path: '/admin/notice/:id',
    name: 'admin-notice-edit',
    component: () => import('@/views/admin/notice/notice-edit.vue'),
    meta: {
      resource: 'admin-notice',
    },
  },
  {
    path: '/admin/article',
    name: 'admin-article',
    component: () => import('@/views/admin/article.vue'),
  },
  {
    path: '/admin/point/point',
    name: 'admin-point',
    component: () => import('@/views/admin/point/point.vue'),
    meta: {
      resource: 'admin-point',
    },
  },
  {
    path: '/admin/point/withdraw',
    name: 'admin-point-withdraw',
    component: () => import('@/views/admin/point/withdraw.vue'),
    meta: {
      resource: 'admin-point-withdraw',
    },
  },
  {
    path: '/admin/point/point-edit/:id',
    name: 'admin-point-edit',
    component: () => import('@/views/admin/point/point-edit.vue'),
    meta: {
      resource: 'admin-point-withdraw',
    },
  },
  {
    path: '/admin/finance/withdraw',
    name: 'admin-finance-withdraw',
    component: () => import('@/views/admin/finance/withdraw.vue'),
    meta: {
      resource: 'admin-withdraw',
    },
  },
  {
    path: '/admin/finance/withdraw-view/:id',
    name: 'admin-finance-withdraw-view',
    component: () => import('@/views/admin/finance/withdraw-edit.vue'),
    meta: {
      resource: 'admin-withdraw',
    },
  },
  // {
  //   path: '/admin/china/exchange',
  //   name: 'admin-china-exchange',
  //   component: () => import('@/views/admin/china/exchange/exchange.vue'),
  // },
  {
    path: '/admin/china/exchange/:id',
    name: 'admin-china-exchange-check',
    component: () => import('@/views/admin/china/exchange/exchange-check.vue'),
  },
  {
    path: '/admin/goodsconfirm',
    name: 'admin-goodsconfirm',
    component: () => import('@/views/admin/goodsconfirm/goodsconfirm.vue'),
    meta: {
      resource: 'admin-ownerlessproduct',
    },
  },
  {
    path: '/admin/goodsconfirm/create',
    name: 'admin-goodsconfirm-create',
    component: () => import('@/views/admin/goodsconfirm/goodsconfirm-add.vue'),
    meta: {
      resource: 'admin-ownerlessproduct',
    },
  },
  {
    path: '/admin/goodsconfirm/edit/:id',
    name: 'admin-goodsconfirm-edit',
    component: () => import('@/views/admin/goodsconfirm/goodsconfirm-edit.vue'),
    meta: {
      resource: 'admin-ownerlessproduct',
    },
  },
  // รายงาน
  {
    path: '/admin/report',
    name: 'admin-report',
    component: () => import('@/views/admin/report.vue'),
    meta: {
      resource: 'admin-report',
    },
  },
  {
    path: '/admin/nobilling',
    name: 'admin-nobilling',
    component: () => import('@/views/admin/nobilling.vue'),
    meta: {
      resource: 'admin-nobilling',
    },
  },
  {
    path: '/admin/mall-list',
    name: 'admin-mall-list',
    component: () => import('@/views/admin/mall/mall-list.vue'),
    meta: {
      resource: 'admin-mall-list',
    },
  },
  {
    path: '/admin/create-mall',
    name: 'admin-create-mall',
    component: () => import('@/views/admin/mall/mall-add.vue'),
    meta: {
      resource: 'admin-mall-list',
    },
  },
  {
    path: '/admin/edit-mall/:id',
    name: 'admin-edit-mall',
    component: () => import('@/views/admin/mall/mall-edit.vue'),
    meta: {
      resource: 'admin-mall-list',
    },
  },
  {
    path: '/admin/mall_stock',
    name: 'admin-mall-stock',
    component: () => import('@/views/admin/mall-stock.vue'),
  },
  {
    path: '/admin/suggestion',
    name: 'admin-suggestion',
    component: () => import('@/views/admin/suggestion.vue'),
  },
  {
    path: '/admin/faq',
    name: 'admin-faq',
    component: () => import('@/views/admin/faq/faq.vue'),
    meta: {
      resource: 'admin-faq',
    },
  },
  {
    path: '/admin/faq/add',
    name: 'admin-faq-add',
    component: () => import('@/views/admin/faq/faq-add.vue'),
    meta: {
      resource: 'admin-faq',
    },
  },
  {
    path: '/admin/faq/:id',
    name: 'admin-faq-edit',
    component: () => import('@/views/admin/faq/faq-edit.vue'),
    meta: {
      resource: 'admin-faq',
    },
  },
  // Setting routes
  {
    path: '/admin/setting/user-type',
    name: 'admin-user-type',
    component: () => import('@/views/admin/setting/user-type/user-type.vue'),
    meta: {
      resource: 'admin-settingusertype',
    },
  },
  {
    path: '/admin/setting/user-type/edit/:id',
    name: 'admin-user-type-edit',
    component: () => import('@/views/admin/setting/user-type/user-type-edit.vue'),
    meta: {
      resource: 'admin-settingusertype',
    },
  },
  {
    path: '/admin/setting/role',
    name: 'admin-role',
    component: () => import('@/views/admin/setting/role/role.vue'),
    meta: {
      resource: 'admin-settingrole',
    },
  },
  {
    path: '/admin/setting/role/create',
    name: 'admin-role-create',
    component: () => import('@/views/admin/setting/role/role-add.vue'),
    meta: {
      resource: 'admin-settingrole',
    },
  },
  {
    path: '/admin/setting/role/edit/:id',
    name: 'admin-role-edit',
    component: () => import('@/views/admin/setting/role/role-edit.vue'),
    meta: {
      resource: 'admin-settingrole',
    },
  },
  {
    path: '/admin/setting/product-type',
    name: 'admin-product-type',
    component: () => import('@/views/admin/setting/product-type/product-type.vue'),
    meta: {
      resource: 'admin-product-type',
    },
  },
  {
    path: '/admin/setting/product-type/create',
    name: 'admin-product-type-create',
    component: () => import('@/views/admin/setting/product-type/product-type-add.vue'),
  },
  {
    path: '/admin/setting/shipping-type',
    name: 'admin-shipping-type',
    component: () => import('@/views/admin/setting/shipping-type/shipping-type.vue'),
    meta: {
      resource: 'admin-shipping-type',
    },
  },
  {
    path: '/admin/setting/shipping-type/create',
    name: 'admin-shipping-type-create',
    component: () => import('@/views/admin/setting/shipping-type/shipping-type-add.vue'),
    meta: {
      resource: 'admin-shipping-type',
    },
  },
  {
    path: '/admin/setting/shipping-type/edit/:id',
    name: 'admin-shipping-type-edit',
    component: () => import('@/views/admin/setting/shipping-type/shipping-type-edit.vue'),
    meta: {
      resource: 'admin-shipping-type',
    },
  },
  {
    path: '/admin/setting/bank',
    name: 'admin-setting-bank',
    component: () => import('@/views/admin/setting/bank.vue'),
    meta: {
      resource: 'admin-bank',
    },
  },
  {
    path: '/admin/setting/promotion/',
    name: 'admin-promotion',
    component: () => import('@/views/admin/setting/promotion/promotion.vue'),
    meta: {
      resource: 'admin-promotion',
    },
  },
  {
    path: '/admin/setting/promotion/create',
    name: 'admin-promotion-create',
    component: () => import('@/views/admin/setting/promotion/promotion-add.vue'),
    meta: {
      resource: 'admin-promotion',
    },
  },
  {
    path: '/admin/setting/promotion/edit/:id',
    name: 'admin-promotion-edit',
    component: () => import('@/views/admin/setting/promotion/promotion-edit.vue'),
    meta: {
      resource: 'admin-promotion',
    },
  },
  {
    path: '/admin/setting/user-type/create',
    name: 'admin-user-type-create',
    component: () => import('@/views/admin/setting/user-type/user-type-add.vue'),
    meta: {
      resource: 'admin-settingusertype',
    },
  },
  {
    path: '/admin/setting/coupon',
    name: 'admin-coupon',
    component: () => import('@/views/admin/setting/coupon/coupon.vue'),
    meta: {
      resource: 'admin-coupon',
    },
  },
  {
    path: '/admin/setting/coupon/create',
    name: 'admin-coupon-create',
    component: () => import('@/views/admin/setting/coupon/coupon-add.vue'),
    meta: {
      resource: 'admin-coupon',
    },
  },
  {
    path: '/admin/setting/coupon/edit/:id',
    name: 'admin-coupon-edit',
    component: () => import('@/views/admin/setting/coupon/coupon-edit.vue'),
    meta: {
      resource: 'admin-coupon',
    },
  },
  {
    path: '/admin/setting/general',
    name: 'admin-general',
    component: () => import('@/views/admin/setting/general.vue'),
    meta: {
      resource: 'admin-general',
    },
  },
  {
    path: '/admin/setting/alipay',
    name: 'admin-alipay',
    component: () => import('@/views/admin/setting/alipay.vue'),
    meta: {
      resource: 'admin-alipay-Account',
    },
  },
  {
    path: '/admin/setting/service-price',
    name: 'admin-service-price',
    component: () => import('@/views/admin/setting/service-price.vue'),
    meta: {
      resource: 'admin-service-price',
    },
  },
  {
    path: '/admin/setting/withdraw-unlock',
    name: 'admin-withdraw-unlockaq',
    component: () => import('@/views/admin/setting/withdraw-unlock.vue'),
    meta: {
      resource: 'admin-withdraw-unlock',
    },
  },
  // {
  //   path: '/admin/runtrack',
  //   name: 'admin-runtrack',
  //   component: () => import('@/views/admin/runtrack/view.vue'),
  // },

  {
    path: '/admin/verifyInfomation',
    name: 'admin-verifyInfomation',
    component: () => import('@/views/admin/verify/infomation.vue'),
    meta: {
      resource: 'admin-verifyInfomation',
    },
  },
  {
    path: '/admin/verifyAccount',
    name: 'admin-verifyAccount',
    component: () => import('@/views/admin/verify/account.vue'),
    meta: {
      resource: 'admin-verifyAccount',
    },
  },
  {
    path: '/admin/RunTrack',
    name: 'admin-RunTrack',
    component: () => import('@/views/admin/RunTrack.vue'),
    meta: {
      resource: 'admin-RunTrack',
    },
  },
  {
    path: '/admin/commission',
    name: 'admin-commission',
    component: () => import('@/views/admin/commission.vue'),
    meta: {
      resource: 'admin-commission',
    },
  },
  {
    path: '/admin/closework',
    name: 'admin-closework',
    component: () => import('@/views/admin/closework.vue'),
    meta: {
      resource: 'admin-closework',
    },
  },
]
