import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    BaseWsUrl: 'wss://api.anan-cargo.com',
    // BaseWsUrl: 'ws://127.0.0.1:4444',
    WsMain: null,
    WsCart: null,
    WsNotiAgent: null,
    WsChat: null,
    WsChatAdmin: null,
    WsDashboard: null,
    WsChatProfile: null,
    WsSendNotify: null,
    WsSendRuntrack: null,
    WsNotiSound: null,
    Wsnotifycount: null,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_WSMAIN(state, val) {
      state.WsMain = val
    },
    SET_WSCART(state, val) {
      state.WsCart = val
    },
    SET_WSNOTIAG(state, val) {
      state.WsNotiAgent = val
    },
    SET_WSCHAT(state, val) {
      state.WsChat = val
    },
    SET_WSCHATADMIN(state, val) {
      state.WsChatAdmin = val
    },
    SET_WSDASHBOARD(state, val) {
      state.WsDashboard = val
    },
    SET_WSCHATPROFILE(state, val) {
      state.WsChatProfile = val
    },
    SET_WSSENDNOTIFY(state, val) {
      state.WsSendNotify = val
    },
    SET_WSRUNTRACK(state, val) {
      state.WsSendRuntrack = val
    },
    SET_WSNOTISOUND(state, val) {
      state.WsNotiSound = val
    },
    SET_WSNOTIFYCOUNT(state, val) {
      state.WsNotifycount = val
    },

  },
  actions: {},
}
